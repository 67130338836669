<template>
    <div id="shipment">
        <b-alert :show="!finished" variant="danger" class="mb-1">
            Отгрузочный день {{shortDate}} не звершен. Проверьте столбец "Считать отгруженным"
        </b-alert>
        <b-card no-body class="mb-2 p-1">
            <div v-b-toggle.filters class="row align-items-center">
                <div class="col-4">
                    <span class="h5" v-if="filters.date">{{formattedDate}}</span>
                </div>
                <div class="col-8 text-right">
                    <button
                        :disabled="plansLoading"
                        class="btn btn-success mr-1"
                        @click.stop="exportToCSV"
                    >
                        Экспорт в CSV
                    </button>
                    <b-button variant="primary" @click="load" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </div>
            </div>
            <b-collapse id="filters" class="d-print-none">
                <b-card-body>
                    <b-row>
                        <div class="col-md-3">
                            <b-form-group description="Дата отгрузки с производства">
                                <date-time-picker
                                    locale="ru"
                                    input-size="sm"
                                    position="bottom"
                                    label="Дата отгрузки"
                                    :no-label="true"
                                    :no-header="true"
                                    :no-button="true"
                                    :no-clear-button="true"
                                    :auto-close="true"
                                    :only-date="true"
                                    :format="null"
                                    formatted="DD.MM.YYYY"
                                    v-model="filters.date"
                                ></date-time-picker>
                            </b-form-group>
                        </div>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" v-b-toggle.acts-collapse class="dropdown-toggle">
                <span
                    style="display: inline-block; font-weight: 600; font-size: 18px; line-height: 35px; margin-right: 5px;"
                >Накладные</span>
            </b-card-header>
            <b-collapse id="acts-collapse" class="d-print-none">
                <b-card-body>
                    <b-alert v-if="actsLoading" show style="text-align: center; margin-bottom: 0;">
                        <i class="fas fa-spinner fa-spin" />
                    </b-alert>
                    <table v-else id="acts" class="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 1px"></th>
                                <th>Дата</th>
                                <th>Номер накладной</th>
                                <th style="width: 1px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="acts.length === 0">
                                <td colspan="4" class="text-center">Нет отгрузок</td>
                            </tr>
                            <tr v-for="act in acts" :key="act.id">
                                <td>
                                    <i
                                        v-if="act.confirmed"
                                        style="color: green"
                                        class="fas fa-check-circle"
                                        title="Отгрузка подтверждена"
                                    ></i>
                                    <i
                                        v-else
                                        style="color: red"
                                        class="fas fa-exclamation-circle"
                                        title="Отгрузка не подтверждена"
                                    ></i>
                                </td>
                                <td>{{moment(act.created_at).format('DD.MM.YYYY HH:mm')}}</td>
                                <td>{{act.timestamp}}</td>
                                <td>
                                    <button
                                        :disabled="!!act.downloading"
                                        @click="download(act)"
                                        class="btn btn-success btn-sm"
                                    >
                                        <i v-if="!!act.downloading" class="fas fa-spinner fa-spin"></i>
                                        <i v-else class="fas fa-download"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-table id="plans" responsive :items="items" :fields="fields" head-variant="dark" foot-variant="dark" :foot-clone="!plansLoading">
            <template #head(finished)="data">
                <span>
                    Считать <br /> отгруженным
                    <b-icon
                        id="help"
                        icon="question-circle-fill"
                        variant="info"
                        class="rounded-circle bg-light"
                    />

                    <b-tooltip target="help" triggers="hover">
                        Проставляется автоматически, после отгрузки всего объема.
                        Либо вручную, с указанием комментария
                    </b-tooltip>
                </span>
            </template>
            <template #cell(shipped_count_by_act)="data">
                <span
                    v-for="items in data.value"
                    v-bind:key="items[0].act.id"
                    style="white-space: nowrap;"
                >
                    ({{ items.reduce((shippedCount, item) => shippedCount + +item.count, 0) }})
                    <a
                        href="#"
                        @click="download(items[0].act)"
                    >{{ items[0].act.timestamp }}</a>
                    <br />
                </span>
            </template>
            <template #cell(finished)="data">
                <span>
                    <b-form-checkbox
                        v-model="data.value"
                        @change="showAssumeFinishedModal(data.item)"
                        :disabled="data.value || !accessForAssumeFinished"
                        :id="`assume_finished_${data.item[0].nomenclature.id}`"
                    />
            </span>
            </template>
            <template #foot(nomenclature_name)="data">
                Итого:
            </template>
            <template #foot(required_count_on_spb)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(required_count_on_msk)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(total_required_count)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(on_stock_count)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(total_shipped_count)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(total_remains_count)="data">
                {{ items.reduce((total, plans) => total + data.field.formatter(null, data.field.key, plans), 0) }}
            </template>
            <template #foot(finished)>
                &nbsp;
            </template>
            <template #foot(shipped_count_by_act)>
                &nbsp;
            </template>
        </b-table>
        <b-alert :show="plansLoading" style="text-align: center;">
            <i class="fas fa-spinner fa-spin" />
        </b-alert>
    </div>
</template>

<script>
import moment from "moment";
import ShipmentComment from "./ShipmentComment";
import Downloader from '@utils/Downloader';
import DateTimePicker from 'vue-ctk-date-time-picker';
import {ROLE_ADMIN, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR} from "@utils/Roles";
import {PRODUCTION_PLANS_ENDPOINT, SHIPMENT_ACT_ENDPOINT} from "@utils/endpoints";

const CITY_SPB = "Санкт-Петербург"
const CITY_MSK = "Москва"

const SHIPMENT_COMMENT_MODAL_PARAMS = {
    width: 800,
    height: 'auto',
    adaptive: true,
    scrollable: true
}

export default {
    name: 'ShipmentTaskList',
    components: {
        DateTimePicker,
    },
    watch: {
        "filters.date"() {
            this.load()
        }
    },
    data() {
        return {
            moment,
            filters: {
                date: moment()
            },
            fields: [
                {
                    key: "nomenclature_name",
                    label: "Номенклатура",
                    formatter(value, key, plans) {
                        return plans[0].nomenclature.name
                    }
                },
                {
                    key: "required_count_on_spb",
                    label: "На СПб",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans
                            .map(plan =>
                                plan.markupPresets
                                    .filter(markupPreset => markupPreset.city === CITY_SPB)
                                    .reduce((requiredCount, markupPreset) => requiredCount + +markupPreset.pivot.count, 0)
                            )
                            .reduce((totalRequiredCount, requiredCount) => totalRequiredCount + requiredCount, 0)
                    }
                },
                {
                    key: "required_count_on_msk",
                    label: "На Москву",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans
                            .map(plan =>
                                plan.markupPresets
                                    .filter(markupPreset => markupPreset.city === CITY_MSK)
                                    .reduce((planCount, markupPreset) => planCount + +markupPreset.pivot.count, 0)
                            )
                            .reduce((requiredCount, planCount) => requiredCount + planCount, 0)
                    }
                },
                {
                    key: "total_required_count",
                    label: "Итого к отгрузке",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans
                            .map(plan => plan.markupPresets.reduce((planCount, markupPreset) => planCount + +markupPreset.pivot.count, 0))
                            .reduce((requiredCount, planCount) => requiredCount + planCount, 0)
                    }
                },
                {
                    key: "on_stock_count",
                    label: "Есть на складе",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans.reduce((totalOnStockCount, plan) => totalOnStockCount + plan.on_stock_count, 0)
                    }
                },
                {
                    key: "total_shipped_count",
                    label: "Отгружено",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans[0].shipment_act_items.reduce((shippedCount, item) => shippedCount + +item.count, 0)
                    }
                },
                {
                    key: "total_remains_count",
                    label: "Осталось отгрузить",
                    thClass: "text-center",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        const totalShippedCount = plans[0].shipment_act_items.reduce((shippedCount, item) => shippedCount + +item.count, 0)
                        const totalRequiredCount = plans
                            .map(plan => plan.markupPresets.reduce((planCount, markupPreset) => planCount + +markupPreset.pivot.count, 0))
                            .reduce((requiredCount, planCount) => requiredCount + planCount, 0)
                        return totalRequiredCount - totalShippedCount
                    }
                },
                {
                    key: "finished",
                    label: "Считать отгруженным",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return plans.every(plan => plan.finished)
                    }
                },
                {
                    key: "shipped_count_by_act",
                    label: "Накладные",
                    tdClass: "text-center",
                    formatter(value, key, plans) {
                        return Object.values(
                            plans[0].shipment_act_items.reduce((shipmentItemsByActId, item) => {
                                shipmentItemsByActId[item.act.id] = shipmentItemsByActId[item.act.id] || []
                                shipmentItemsByActId[item.act.id].push(item)
                                return shipmentItemsByActId
                            }, {})
                        )
                    }
                }
            ],
            acts: [],
            plans: [],
            actsLoading: false,
            plansLoading: false
        }
    },
    computed: {
        accessForAssumeFinished() {
            return this.$auth.user().roles.some(role => [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR].includes(role))
        },
        formattedDate() {
            return moment(this.filters.date).format("DD.MM.YYYY")
        },
        items() {
            return Object.values(
                this.plans.reduce((items, plan) => {
                    items[plan.nomenclature.id] = items[plan.nomenclature.id] || []
                    items[plan.nomenclature.id].push(plan)
                    return items
                }, {})
            )
        },
        shortDate() {
            return moment(this.filters.date).format("DD.MM")
        },
        finished() {
            return this.plans.every(plan => plan.finished)
        }
    },
    methods: {
        download(act) {
            if (!!act.downloading) {
                return
            }
            act.downloading = true
            this.$forceUpdate()
            this.$http
                .get(SHIPMENT_ACT_ENDPOINT + `/waybill/${act.timestamp}/pdf`, {
                    responseType: "arraybuffer",
                    params: {
                        without_loading: true
                    }
                })
                .then(response => {
                    Downloader.download(
                        response.data,
                        "application/pdf",
                        `Накладная ${act.timestamp} на отгрузку ${moment(act.created_at).format("DD.MM.YYYY HH-mm")}.pdf`
                    )
                })
                .catch(response => {
                    this.$bvToast.toast(response.data.message, {variant: 'danger'});
                })
                .finally(() => {
                    act.downloading = false
                    this.$forceUpdate()
                })
        },
        showAssumeFinishedModal(plans) {
            this.$nextTick().then(() => {
                const nomenclature = plans[0].nomenclature
                const checkbox = document.getElementById(`assume_finished_${nomenclature.id}`)
                checkbox.removeAttribute("checked")
                checkbox.checked = false
                this.$modal.show(ShipmentComment, {plans, onConfirm: this.loadPlans}, SHIPMENT_COMMENT_MODAL_PARAMS)
            })
        },
        exportToCSV() {
            // UTF-8 BOM (byte-order-mark) for Excel ¯\_(ツ)_/¯
            let content = "\ufeff" + "Номенклатура;Отгружено\r\n"
            this.items.forEach(plans => {
                const nomenclatureName = plans[0].nomenclature.name.replace(/;/g, "")
                const shippedCount = plans[0].shipment_act_items.reduce((shippedCount, item) => shippedCount + +item.count, 0)
                content += `${nomenclatureName};${shippedCount}\r\n`
            })
            Downloader.download(content, "text/csv;charset=utf-8", `Отгрузка ${this.formattedDate}.csv`)
        },
        loadActs() {
            this.actsLoading = true
            this.acts = []
            this.$http
                .get(SHIPMENT_ACT_ENDPOINT, {
                    params: {
                        filters: {
                            production_plan_date: moment(this.filters.date).format("YYYY-MM-DD")
                        },
                        without_loading: true,
                        limit: 1000
                    }
                })
                .then(response => response.data.data.data)
                .then(acts => {
                    this.acts = acts
                })
                .finally(() => {
                    this.actsLoading = false
                })
        },
        loadPlans() {
            this.plansLoading = true
            this.plans = []
            this.$http
                .get(PRODUCTION_PLANS_ENDPOINT, {
                    params: {
                        filters: {
                            date: moment(this.filters.date).format("YYYY-MM-DD"),
                            has_shipment_tasks: true
                        },
                        with: [
                            "nomenclature", //
                            "markupPresets"
                        ],
                        appends: [
                            "on_stock_count", //
                            "shipment_act_items",
                            "finished"
                        ],
                        without_loading: true,
                        limit: 1000
                    }
                })
                .then(response => response.data.data.data)
                .then(plans => {
                    this.plans = plans
                })
                .finally(() => {
                    this.plansLoading = false
                })
        },
        load() {
            this.loadActs()
            this.loadPlans()
        }
    },
    mounted() {
        if (this.$route.params.date) {
            this.filters.date = this.$route.params.date
        }
        this.load()
    }
}
</script>

<style>
    #shipment {
        margin-bottom: 2rem;
    }

    #shipment #acts {
        margin-bottom: 0 !important;
    }

    #shipment #acts tr {
        background-color: white !important;
    }

    #shipment #plans {
        margin-bottom: 0 !important;
    }

    #shipment #plans tr {
        background-color: white !important;
    }

    #shipment #plans tfoot th {
        color: black;
        border-color: #c8ced3 !important;
        background-color: white !important;
    }
</style>

<template>
    <b-card id="shipment-comment" class="mb-0">
        <div class="card-header">Считать отгруженным</div>
        <div class="card-body" style="margin: 0.75rem;">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group label="Комментарий">
                            <b-form-textarea v-model="comment" />
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-center">
            <b-button @click="assumeFinished" variant="success" :disabled="!comment">Подтвердить</b-button>
            <b-button @click="$emit('close')" variant="danger">Отменить</b-button>
        </div>
    </b-card>
</template>

<script>
    import { PRODUCTION_PLANS_ENDPOINT } from "../../utils/endpoints"

    export default {
        name: "ShipmentComment",
        props: {
            plans: {
                type: Array,
                required: true,
            },
            onConfirm: {
                type: Function,
                required: true,
            },
        },
        data() {
            return {
                comment: null,
            }
        },
        methods: {
            assumeFinished() {
                const nomenclature = this.plans[0].nomenclature
                const requests = this.plans.map((plan) => this.assumeFinishedRequest(plan))
                Promise.all(requests)
                    .then(() => {
                        this.$bvToast.toast(`Задание "${nomenclature.name}" успешно завершено`, {variant: 'success'});
                    })
                    .catch((error) => {
                        console.error(error)
                        this.$bvToast.toast(`Не удалось завершить задание "${nomenclature.name}"`, {variant: 'danger'});
                    })
                    .finally(() => {
                        this.onConfirm()
                    })
            },
            assumeFinishedRequest(plan) {
                return this.$http.put(PRODUCTION_PLANS_ENDPOINT + "/" + plan.id + "/assume-finished", {
                    comment: this.comment,
                })
            },
        },
    }
</script>

<style scoped>
    .card-body {
        padding: 0 !important;
    }
</style>
